.info-keluarga-font-title{
    width: 100%;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: #3598DC;
    margin-bottom: 15px;
    text-align: left;
}

.struktur-saudara-wraper{
    width: 100%;
}

.input-struktur{
    background: #FAFAFA;
    border: 0.5px solid #D1D1D1;
    box-sizing: border-box;
    border-radius: 4px;
    height: 44px;
    width: 160px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 6px 12px ;
}

.input-struktur-text{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #666666;
}

.input-struktur-select{
    background: #FAFAFA;
    border: 0.5px solid #D1D1D1;
    box-sizing: border-box;
    border-radius: 4px;
    min-width: 45px;
    height: 32px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #666666;
    padding-left: 5px;
}

.struktur-saudara-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.from-input-flicker{
    margin: 0 14px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #666666;
}

.on-center-calendar .react-date-picker__inputGroup{
    text-align: left;
}

.on-center-calendar{
    width: 100%;
}

.btn-saudara-wrap{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}

.input-struktur-select:focus{
    outline: none;
}

.input-struktur-select option {
    outline: none;
    border: none;
}

.divider-style-input-data .MuiDivider-root{
    background-color: #DDDDDD !important;
    height: 0.5px !important;
}

.MuiInputBase-input::placeholder{
    color: #868686 !important;
}

.btn-add-saudara{
    width: 170px;
    margin-right: 15px !important;
}

.btn-add-sma{
    width: 230px;
    margin-right: 15px !important;
}

.btn-delete-saudara{
    width: 150px;
}

@media (max-width: 966px){
    .struktur-saudara-row{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
    }
    .from-input-flicker{
        margin: 10px 0px;
        width: 100%;
    }
    .input-struktur{
        width: 100%;
        justify-content: center;
    }
}

@media (max-width: 900px){
    .struktur-saudara-row{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
    }
    .from-input-flicker{
        margin: 10px 0px;
        width: 100%;
    }
    .input-struktur{
        width: 100%;
        justify-content: center;
    }
}

@media (max-width: 319px){
    .btn-add-sma-txt{
        font-size: 12px;
        line-height: 14px;
    }
}