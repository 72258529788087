.box-white-blue-upload-berkas{
    background: #F1F9FF;
    border-radius: 10px;
    width: 100%;
    height: 100%;
    min-height: 180px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 10px;
    box-sizing: border-box;
    margin-bottom: 10px;
}

.box-white-upload-berkas{
    margin: 6px 6px;
    background: #FFFFFF;
    border: 0.5px solid #E8E7E7;
    box-sizing: border-box;
    box-shadow: 0px 1px 2px rgba(117, 117, 117, 0.1);
    border-radius: 10px;
    max-width: 286px;
    width: 286px;
    min-height: 280px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    padding-bottom: 10px;
}

.box-white-blue-download-berkas-done{
    background: #F1F9FF;
    border-radius: 10px;
    width: 100%;
    height: 180px;
    display: flex;
    flex-direction: column;
    padding: 16px;
    padding-left: 25px;
    justify-content: center;
    align-items: center;
}

.box-litle-done-upload{
    background: #3598DC;
    border-radius: 10px;
    max-width: 90px;
    min-width: 90px;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 17px;
}

.box-white-row{
    display: flex;
    flex-direction: row;
    justify-items: flex-start;
    align-items: flex-start;
    margin-right: auto;
}

.box-white-blue-download-berkas-text{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    margin-top: 19px;
    color: #3598DC;
    width: 100%;
}

.box-white-row-btn{
    display: flex;
    flex-direction: row;
}

.box-white-column{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 10px;
}

.box-white-size-file{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #BBBBBB;
    margin-top: 6px;
    font-weight: bold;
}

.box-white-file-name{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #666666;
    margin-top: 10px;
    max-width: 145px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
}