.text-editor-wrapping2021-with-title{
    display: flex;
    flex-direction: column;
}

.exercise-test-text{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: #666666;
    margin-right: auto;
    text-align: left;
    display: flex;
    margin-right: 8px;
}

.text-editor-wrap{
    margin-top: 20px;
    border: 0.5px solid #C4C4C4;
    border-radius: 0px 0px 4px 4px;
}

.text-editor-wrap-red{
    margin-top: 20px;
    border: 0.5px solid red;
    border-radius: 0px 0px 4px 4px;
}

.public-DraftStyleDefault-block{
    margin: 2px !important;
}

.alert-text-editor{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 14px;
}

.text-alert-editor-font{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #999999;
    margin-left: 5px;
}

.text-info-editor{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: #444444;
    text-align: left;
    margin-top: 40px;
    width: 55%;
}

.wrapper-paraf-city{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 15px;
    width: 100%;
}

.little-title-info-paraf{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #666666;
    margin-left: 16px;
    display: block;
}

.little-title-info-paraf-mobile{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #666666;
    margin-top: 10px;
    display: none;
}

.data-pribadi-input-paraf{
    width: 198px;
    height: 44px;
    background: #FAFAFA;
    border: 0.5px solid #D1D1D1;
    box-sizing: border-box;
    border-radius: 4px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    padding: 0px 19px;
    color: #666666;
}

.data-pribadi-input-paraf:focus{
    outline: none;
}

.data-pribadi-input-paraf::placeholder{
    color: #C9C9C9;
}

/* arrows */
select.data-pribadi-input-paraf {
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url('../../../../images/inputData/dropdown.png');
    background-repeat: no-repeat;
    background-position: calc(100% - 10px) calc(1em + 2px);
}

.wrapper-text-editor{
    width: 100%;
    border: 0.5px solid #C4C4C4;
    box-sizing: border-box;
    border-radius: 4px;
}

.wrapper-text-editor-alert{
    border: 0.5px solid red;
}

.editor-text-editor{
    min-height: 279.03px;
    padding: 5px 10px;
    font-size: 16px;
    z-index: 2;
}

.footer-input-text-editor{
    margin-top: 30px;
    width: 100%;
    margin-right: auto;
    text-align: left;
}

.footer-input-text-editor-title{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: #444444;
    max-width: 600px;
    width: 100%;
}

@media (max-width: 972px){
    .little-title-info-paraf{
        display: none;
    }
    .little-title-info-paraf-mobile{
        display: block;
    }
}

@media (max-width: 330px){
    .wrapper-paraf-city{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 15px;
        width: 100%;
    }
    .data-pribadi-input-paraf{
        margin-top: 10px;
    }
}

