.login-container-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.login-font-top{
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #444444;
    margin-top: 30px;
}

.login-container-card{
    border: 0.5px solid #ECECEC;
    box-sizing: border-box;
    background-color: white;
    border-radius: 4px;
    width: 458px;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px 30px;
}

.password-wraper{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.card-font-text-username{
    font-family: 'Roboto', sans-serif;
    margin-right: auto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #666666;
}

.card-font-text-password{
    font-family: 'Roboto', sans-serif;
    margin-right: auto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #666666;
}

.eye-custom{
    color: #D1D1D1;
    font-size: 26px;
    margin-left: -40px;
    margin-right: 10px;
    margin-top: 10px;
    cursor: pointer;
}

.eye-custom2{
    color: #D1D1D1;
    font-size: 26px;
    margin-left: -43px;
    margin-right: 10px;
    margin-top: 10px;
    cursor: pointer;
}

.bottom-container-wrapper{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 10px;
}

.lupa-password-font{
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: right;
    color: #3598DC;
}

.bannerYES-new2021{
    width: 550px;
}

.login-input-theme{
    width: 100%;
    height: 44px;
    background: #FAFAFA;
    border: 0.5px solid #D1D1D1;
    box-sizing: border-box;
    border-radius: 4px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    transition: 0.5s;
    padding: 0px 19px;
    margin-top: 10px;
}

.login-input-theme::placeholder {
    color: #D1D1D1;
}

.login-input-theme:focus{
    outline: none;
    box-shadow: 0px 0px 5px 2px #B0DFFF;
    border: 1px solid #B0DFFF;
}

.bannerKAKEHASHI-new2021{
    width: 500px;
}

.bannerAFS-new2021{
    width: 450px;
}

@media (max-width: 565px){
    .bannerYES-new2021{
        width: 500px;
    }
    .bannerKAKEHASHI-new2021{
        width: 400px;
    }
    .bannerAFS-new2021{
        width: 350px;
    }
}

@media (max-width: 483px){
    .bannerYES-new2021{
        width: 330px;
    }
    .login-container-card{
        width: 330px;
        padding: 30px 30px;
    }
    .bannerKAKEHASHI-new2021{
        width: 350px;
    }
    .bannerAFS-new2021{
        width: 300px;
    }
}

@media (max-width: 341px){
    .bannerYES-new2021{
        width: 300px;
    }
    .login-container-card{
        width: 300px;
        padding: 30px 30px;
    }
    .bannerKAKEHASHI-new2021{
        width: 300px;
    }
    .bannerAFS-new2021{
        width: 270px;
    }
}