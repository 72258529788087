.component-input-register{
    display: flex;
    flex-direction: column;
    margin-top: 22px;
    width: 100%;
}

.label-card{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #999999;
    margin-right: auto;
    margin-top: 8px;
}

.activation-input-62{
    width: 35px;
    height: 44px;
    border: none;
    outline: none;
    box-sizing: border-box;
    border-radius: 4px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    transition: 0.5s;
}

.activation-input-hp{
    width: 400-75px;
    height: 44px;
    border: none;
    outline: none;
    box-sizing: border-box;
    border-radius: 4px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    transition: 0.5s;
    background: none;
    padding-left: 15px;
}

.nohp-input-wrapper{
    margin-top: 10px;
    height: 44px;
    background: #FAFAFA;
    border: 0.5px solid #D1D1D1;
    box-sizing: border-box;
    border-radius: 4px;
    transition: 0.5s;
    padding: 0px 19px;
}

.activation-input-hp::placeholder {
    color: #D1D1D1;
}

.nohp-input-wrapper:focus-within{
    outline: none;
    box-shadow: 0px 0px 5px 2px #B0DFFF;
    border: 1px solid #B0DFFF;
}

.logo-binabud-register{
    width: 400px;
}

.register-container-wrapper{
    padding: 30px 0px;
}

.register-contain-text{
    margin-top: 20px;
}

.register-container-card{
    border: 0.5px solid #ECECEC;
    box-sizing: border-box;
    background-color: white;
    border-radius: 4px;
    width: 458px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px 30px;
    margin-top: 20px;
}

@media (max-width: 464px){
    .logo-binabud-register{
        width: 300px;
    }
    .register-container-card{
        width: 350px;
    }
}

@media (max-width: 355px){
    .logo-binabud-register{
        width: 290px;
    }
    .register-container-card{
        width: 300px;
    }
}