.modal-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    margin-top: 80px;
    width: 80%;
}

.modal-wrapper-chapter{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    margin-top: 50px;
    width: 80%;
}

.font-modal-one{
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #444444;
}

.font-modal-two{
    margin-top: 11px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #3598DC;
}

.font-modal-three{
    margin-top: 51px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #888888;
    text-align: left;
    margin-bottom: 19px;
    margin-right: auto;
}

.container-btn-wrapper{
    display: flex;
    flex-direction: row;
}

.font-modal-one-chapter{
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #666666;
}

.font-modal-two-chapter{
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    width: 70%;
    text-align: center;
    color: #666666;
}

.font-modal-three-chapter{
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #888888;
    margin-top: 50px;
}

.phone-custom{
    color: #3598DC;
    font-size: 17px;
}

.twitter-custom{
    color: #3598DC;
    font-size: 18px;
}

// ---------------------------------------------
.ModalChapter {
    position: fixed;
    z-index: 99999999999999;
    background: #FFFFFF;
    border-radius: 10px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    transition: all 0.3s ease-out;
    animation: popup 0.3s ease-in;
    animation-fill-mode: both;
}

.modal-chapter-wrapper{
    min-width: 554px;
    max-width: 554px;
    min-height: 328px;
    display: flex;
    flex-direction: column;
    transition: 0.5s;
}

@keyframes popup {
    0% {
        transform: translate(-50%, -10%);
        opacity: 0;
    }
    30% {
        transform: translate(-50%, -15%);
        opacity: 0.3;
    }
    50% {
        transform: translate(-50%, -25%);
        opacity: 0.5;
    }
    75% {
        transform: translate(-50%, -35%);
        opacity: 0.7;
    }
    100% {
        transform: translate(-50%, -50%);
        opacity: 1;
    }
}

@keyframes popdown {
    0% {
        transform: translate(-50%, -50%);
        opacity: 1;
    }
    30% {
        transform: translate(-50%, -35%);
        opacity: 0.7;
    }
    50% {
        transform: translate(-50%, -25%);
        opacity: 0.5;
    }
    75% {
        transform: translate(-50%, -15%);
        opacity: 0.3;
    }
    100% {

        transform: translate(-50%, -10%);
        opacity: 0;
    }
}

.Backdrop {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 999999999999;
    left: 0;
    top: 0;
    background-color: rgba(0,0,0,0.2);
}

.modal-header-1235{
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0px 15px;
}

.fa-times-closed2414{
    color: #aaaaaa;
    cursor: pointer;
    transition: 0.5s;
}

.fa-times-closed2414:hover{
    color: rgba(255, 0, 0, 0.603);
}

.chapter-name-modal-1412{
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #666666;
    margin-top: 15px;
}

.chapter-address-modal-1412{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: #666666;
    margin-top: 15px;
    padding: 0px 50px;
}

.chapter-info-modal-1412{
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #888888;
}

.btn-custom-modal-332{
    min-height: 45px;
    background-color: #F5F8FD;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #3598DC;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 5px 50px;
    transition: 0.5s ease-in;
}

.btn-custom-modal-332:hover{
    background-color: #f5f8fd8e;
}

.top-876831{
    margin-top: 15px;
}

@media (max-width: 560px){
    .modal-chapter-wrapper{
        min-width: 400px;
        max-width: 400px;
        min-height: 328px;
        display: flex;
        flex-direction: column;
    }
}

@media (max-width: 405px){
    .modal-chapter-wrapper{
        min-width: 350px;
        max-width: 350px;
        min-height: 328px;
        display: flex;
        flex-direction: column;
    }
    .chapter-info-modal-1412{
        font-size: 14px;
        line-height: 18px;
        padding: 0px 50px;
    }
    .top-876831{
        margin-top: 8px;
    }
}

@media (max-width: 357px){
    .modal-chapter-wrapper{
        min-width: 300px;
        max-width: 300px;
        min-height: 328px;
        display: flex;
        flex-direction: column;
    }
}

.modal-token-wrapper{
    min-width: 554px;
    max-width: 554px;
    min-height: 476px;
    display: flex;
    flex-direction: column;
    transition: 0.5s;
    box-sizing: content-box;
}

.modal-noToken-wrapper{
    min-width: 554px;
    max-width: 554px;
    min-height: 476px;
    display: flex;
    flex-direction: column;
    transition: 0.5s;
    box-sizing: content-box;
}

.modal-notoken-text1{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #444444;
    margin-top: 50px;
}

.modal-notoken-dateblue{
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #3598DC;
    margin-top: 10px;
    display: block;
}

.modal-notoken-dateblue-on{
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #3598DC;
    margin-top: 10px;
    display: none;
}

.modal-notoken-info{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #888888;
    margin-top: 50px;
    margin-right: auto;
    margin-bottom: 15px;
    text-align: left;
}

.modal-notoken-btn1{
    height: 44px;
    width: 100%;
}

.modal-notoken-btn2{
    height: 44px;
    width: 100%;
}

.modal-notoken-textinfosmall{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #888888;
}


.modal-token-group-btn{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.modal-token-group-btn1{
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

@media (max-width: 566px){
    .modal-noToken-wrapper{
        min-width: 400px;
        max-width: 400px;
        min-height: 500px;
        display: flex;
        flex-direction: column;
    }
    .modal-token-wrapper{
        min-width: 400px;
        max-width: 400px;
        min-height: 550px;
    }
    .modal-token-group-btn{
        display: none;
    }
    .modal-token-group-btn1{
        display: block;
    }
}

@media (max-width: 407px){
    .modal-noToken-wrapper{
        min-width: 350px;
        max-width: 350px;
        display: flex;
        flex-direction: column;
    }
    .modal-notoken-dateblue{
        display: none;
    }
    .modal-notoken-dateblue-on{
        display: block;
    }
    .modal-token-wrapper{
        min-width: 350px;
        max-width: 350px;
        min-height: 600px;
    }
}

@media (max-width: 359px){
    .modal-noToken-wrapper{
        min-width: 300px;
        max-width: 300px;
        display: flex;
        flex-direction: column;
    }
    .modal-notoken-dateblue{
        display: none;
    }
    .modal-notoken-dateblue-on{
        display: block;
    }
    .modal-token-wrapper{
        min-width: 300px;
        max-width: 300px;
        min-height: 600px;
    }
}

.modal-token-btn1{
    height: 44px;
    width: 100%;
}

.modal-token-info{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #888888;
    margin-top: 50px;
    margin-right: auto;
    margin-bottom: 15px;
    text-align: left;
}

.modal-token-info-123{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #888888;
    margin-top: 20px;
    margin-right: auto;
    margin-bottom: 15px;
    text-align: left;
}