.forgot-password-font-text{
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #555555;
    margin: 0 auto;
    margin-top: 15px;
    width: 336px;
}

.button-forgot-pass-contain{
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.btn-left-81623{
    width: 100%;
    margin-right: 5px !important;
}

.btn-right-81623{
    width: 100%;
    margin-left: 5px !important;
}

@media (max-width: 470px){
    .button-forgot-pass-contain{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .btn-left-81623{
        margin-right: 0px !important;
    }
    
    .btn-right-81623{
        margin-top: 10px !important;
        margin-left: 0px !important;
    }
}

@media (max-width: 355px){
    .forgot-password-font-text{
        width: 80%;
    }
}