.menu-container{
    display: flex;
    flex-direction: row;
}

.logo-binabudaya-sidebar{
    padding: 0px 22px;
    margin-bottom: 50px;
    margin-top: 48px;
}

.logo-binabudaya-circle-sidebar{
    width: 80%;
    margin: 0 auto;
    padding: 3px 0px;
}

.menu-list-font-active{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    margin-left: 15px;
}

.menu-list-font{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    margin-left: 15px;
}

.wrapper-btn-list-menu{
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.appbar-font-title{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #FFFFFF;
    text-align: left;
}

.appbar-title-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.popup-welcome{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    color: #85CDFF;
}

.custom-popup{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.text-menu-item-popup{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 14px;
    color: #999999;
}

.MuiList-padding{
    padding: 0px !important;
}

.drawer-footer-small{
    background: #FAFAFA;
    height: 55px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 8px;
    line-height: 14px;
    text-align: center;
    color: #BBBBBB;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: content-box;
    padding: 10px 5px;
}

.drawer-footer{
    background: #FAFAFA;
    height: 55px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #BBBBBB;
    display: flex;
    box-sizing: content-box;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.parents-component-seleksi{
    padding-top: 62px + 16px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    min-height: 100vh;
    width: calc(100vw - 230px);
    box-sizing: border-box;
}

.swal2-container {
    z-index: 999999;
    width: 100%;
    box-sizing: border-box;
}

.alert-info-2131249{
    font-weight: bold;
}

.btn-finalisasi-yakin{
    height: 44px;
    background: #3598DC;
    border-radius: 4px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    outline: none;
    border: none;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: 10px;
    cursor: pointer;
}

.btn-finalisasi-yakin-ok{
    height: 44px;
    background: #3598DC;
    border-radius: 4px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    outline: none;
    border: none;
    padding-left: 50px;
    padding-right: 50px;
    margin-left: 10px;
    cursor: pointer;
}

.btn-finalisasi-tidak{
    height: 44px;
    background: #e50000;
    border-radius: 4px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    outline: none;
    border: none;
    padding-left: 20px;
    padding-right: 20px;
    cursor: pointer;
}

@media (max-width: 680px){
    .appbar-font-title{
        font-size: 10px;
        display: none;
    }
    .custom-popup{
        margin-left: auto;
    }
}

@media (max-width: 349px){
    .alert-info-2131249{
        font-size: 12px;
    }
}