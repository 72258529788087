.download-berkas-wripper{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: #FFFFFF;
    padding-left: 20px;
    padding-right: 20px;
    box-shadow: 0px 1px 2px rgba(117, 117, 117, 0.1);
    margin-top: 17px;
    border-radius: 4px 4px 0px 0px;
}

.download-berkas-wripper-2{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: #FFFFFF;
    padding-left: 20px;
    padding-right: 20px;
    box-shadow: 0px 1px 2px rgba(117, 117, 117, 0.1);
    margin-top: 17px;
    border-radius: 0px 0px 4px 4px;
}


.download-berkas-title-header{
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #444444;
    height: 60px;
    display: flex;
    align-items: center;
    width: 100%;
    text-align: left;
}

.document-wrapper-2021{
    padding: 30px 20px;
    display: flex;
    flex-wrap: wrap;
}

.box-white-download-berkas{
    margin: 6px 6px;
    background: #FFFFFF;
    border: 0.5px solid #E8E7E7;
    box-sizing: border-box;
    box-shadow: 0px 1px 2px rgba(117, 117, 117, 0.1);
    border-radius: 10px;
    max-width: 286px;
    width: 286px;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    padding-bottom: 18px;
}

.box-white-berkas-wripper{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 5px;
    margin-bottom: 29px;
    // justify-content: space-between;
    width: 100%;
    align-items: flex-start;
}

.box-white-blue-download-berkas{
    background: #F1F9FF;
    border-radius: 10px;
    width: 100%;
    height: 215px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.box-white-blue-download-berkas-text-dwd{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    margin-top: 19px;
    color: #3598DC;
    width: 70%;
}

.box-white-blue-download-berkas-text-dwd-title{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    text-align: left;
    color: #615d5d;
    width: 100%;
    margin-top: 2px;
    margin-bottom: 15px;
}

.finalisasi-buyback{
    font-family: Roboto;
    font-size: 18px;
    line-height: 21px;
    /* identical to box height */
    flex-direction: row;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 10px;
    text-align: left;
    padding-left: 10px;
    color: #555555;
}

.finalisasi-buyback-blue{
    font-style: italic;
    font-weight: 500;
    text-decoration-line: underline;
    color: #ff0000;
    cursor: pointer;
    transition: 0.5s;
}

.finalisasi-buyback-blue:hover{
    color: #830808;
}

.text-modal-buyback{
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #3598DC;
}

.text-modal-buyback-black{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #666666;
    margin-top: 30px;
}

.buyback-modal-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    padding-top: 70px;
    padding-bottom: 70px;
    width: 100%;
    border-bottom: 1px solid #F4F4F4;
}

.btn-buyback-yakin{
    width: 200px;
    height: 44px;
    background: #3598DC;
    border-radius: 4px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    border: none;
    outline: none;
    cursor: pointer;
    margin-left: 10px;
    transition: 0.5s;
}

.btn-buyback-tidak{
    width: 200px;
    height: 44px;
    border: 1px solid #3598DC;
    box-sizing: border-box;
    border-radius: 4px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #3598DC;
    border: none;
    outline: none;
    cursor: pointer;
    transition: 0.5s;
}

.btn-buyback-yakin:hover{
    background: #1f6da1;
}

.btn-buyback-tidak:hover{
    background: #dfdfdf;
}