.text-editor-essay{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    padding: 15px 21px;
    min-height: 572px;
}

.text-editor-catatan{
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-decoration-line: underline;
    color: #444444;
    margin-bottom: 5px;
}

.wrapper-catatan-container{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
}

.list-item-catatan-txt{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #666666;
    margin-top: 4px;
}

.list-item-catatan-txt-red{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 14px;
    font-weight: 500;
    color: rgb(230, 29, 29);
    margin-top: 20px;
    b {
        color: rgb(255, 0, 0);
    }
}

.catatan-radio-wrapper{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-items: flex-start;
    margin-top: 10px;
    text-align: left;
}