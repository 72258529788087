.input-data-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.input-data-header{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #222222;
}

.bread-cab-container{
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
}

.bread-grey{
    color: #999999;
}

.MuiPaper-elevation4{
    box-shadow: none !important;
}

.appbar-menu-input-2021{
    height: 70px;
}

.btn-input-appbar{
    height: 70px;
    background-color: #4fa2df;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.5s;
}

.btn-input-appbar:hover{
    background-color:#3c98dc;
}

.tabs{
    width: 300px;
    height: 70px;
}

@media (max-width: 2560px) {
    .tabs{
        width: 300px;
    }
}

@media (max-width: 2000px) {
    .tabs{
        width: 150px;
    }
}

@media (max-width: 599px) {
    .tabs{
        height: 50px;
    }
    .appbar-menu-input-2021{
        height: 50px;
    }
}

@media (min-width: 600px) {
    .MuiTab-root {
        min-width: 0px !important; 
    }
}


