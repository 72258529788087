.homepage-container-wrapper{
    background: linear-gradient(179.07deg, #F5F8FD 1.52%, rgba(245, 248, 253, 0) 98.5%);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    scroll-behavior: smooth;
}

.homepage-wrapper{
    width: 90%;
    min-height: 100vh;
    scroll-behavior: smooth;
    padding-bottom: 50px;
}

.appbar-homepage{
    width: 100%;
    top: 0px;
    position: fixed;
    -webkit-box-shadow: -10px 4px 105px 11px rgba(0,0,0,0.67);
    -moz-box-shadow: -10px 4px 105px 11px rgba(0,0,0,0.67);
    box-shadow: -10px 4px 105px 11px rgba(0,0,0,0.67);
    z-index: 99;
}

.homepage-wrapper-middle-one{
    width: 90%;
}

.Logo-navbar-homepage{
    padding-top: 40px;
    display: flex;
    justify-content: flex-start;
}

.homepage-font-blue-big{
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 45px;
    /* or 125% */
    text-align: center;
    color: #3598DC;
}

.homepage-mid-contain{
    margin-top: 66px;
    display: flex;
    flex-direction: column;
}

.homepage-font-grey{
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    /* identical to box height */
    text-align: center;
    color: #444444;
    margin-top: 14px;
}

.homepage-font-black-bold{
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    /* identical to box height */
    color: #000000;
    margin-top: 86px;
}

.homepage-program-list{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 77px;
}

.homepage-program{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 90px;
    max-height: 220px;
    min-height: 220px;
}

.homepage-font-middle-contain{
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    line-height: 30px;
    text-align: center;
    color: #444444;
}

.homepage-font-middle-paragraf{
    margin-top: 20px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #444444;
    width: 80%;
}

.homepage-wrapper-middle{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.homepage-font-middle-double{
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    /* identical to box height */
    color: #444444;
}

.homepage-middle-double{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 67px;
}

.homepage-list-card{
    margin-top: 25px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 15px;
    column-gap: 15px;
}

.homepage-card{
    background: #FFFFFF;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 4px 8px rgba(0, 0, 0, 0.04);
    border-radius: 10px;
    width: 251px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
    transition: 0.5s;
    cursor: pointer;
}

.homepage-card:hover{
    margin-top: -5px;
    box-shadow: 0px 0px 1px rgba(40, 134, 217, 1), 0px 0px 2px rgba(40, 134, 217, 0.295), 0px 4px 8px rgba(40, 134, 217, 0.192);
}

.card-font-top{
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    color: #666666;
    margin-top: 20px;
}

.card-font-middle{
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #666666;
    margin-top: 10px;
}

.card-font-media{
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #3598DC;
}

.homepage-card-title{
    padding: 0px 30px;
    display: flex;
    flex-direction: column;
}

.card-font-bottom{
    margin-top: auto;
    margin-bottom: 20px;
}

.homepage-footer{
    background: linear-gradient(90deg, #2785d8 0%, #66a6ff 100%);
    width: 100%;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 200px;
}

.footer-font{
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #FFFFFF;
}

.homepage-font-blue-big-phone{
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 45px;
    /* or 125% */
    text-align: center;
    color: #3598DC;
    display: none;
}

.homepage-font-grey-phone{
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 21px;
    /* identical to box height */
    text-align: center;
    color: #444444;
    margin-top: 14px;
    display: none;
}

.text-footer-support-standin{
    display: none;
    flex-direction: column;
    width: 90%;
    border-bottom: 1px solid white;
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.text-footer-support{
    border-bottom: 1px solid white;
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.text-footer-support-standin .footer-font{
    text-align: left;
}

.search-chapter-input{
    margin-top: 20px;
    padding: 10px 15px;
    padding-left: 35px;
    outline: none;
    border: 1px solid #c9c9c9;
    border-radius: 10px;
    background: url("../../images/search.png") no-repeat scroll 10px 10px;
    background-size: 15px;
    transition: 0.5s;
}

.search-chapter-input:focus{
    background: url("../../images/search-blue.png") no-repeat scroll 10px 10px;
    background-size: 15px;
    border: 1px solid #2886d9;
}

@media (max-width: 980px) {
    .text-footer-support{
        display: none;
    }
    .text-footer-support-standin{
        display: flex;
    }
}

@media (max-width: 684px) {
    .homepage-font-blue-big{
        display: none;
    }
    .homepage-font-blue-big-phone{
        display: block;
    }
    .homepage-program-list{
        flex-direction: column;
        margin-top: 20px;
    }
    .homepage-program{
        margin-left: 0px;
        margin-top: 20px;
    }
}

@media (max-width: 425px) {
    .homepage-wrapper{
        padding-bottom: 150px;
    }
    .Logo-navbar-homepage{
        justify-content: center;
    }
    .homepage-font-blue-big-phone{
        font-size: 30px;
        line-height: 40px;
    }
    .homepage-font-grey{
        display: none;
    }
    .homepage-font-grey-phone{
        display: block;
    }
    .homepage-middle-double{
        align-items: center;
    }
}