.data-pribadi-button-wripper-3{
    margin-right: auto;
}

.upload-image{
    margin: 0px auto;
    width: calc(472px / 2);
    height: calc(709px / 2);
    position:relative;
    background: #F1F9FF;
    border: 2px dashed #B5E1FF;
    box-sizing: border-box;
    border-radius: 4px;
    overflow:hidden;
    background-size:cover;
}

.upload-image__img{
    width:100%;
    height:100%;
}
.upload-image__overflow{
    position: absolute;
    opacity: 0;
    bottom: 0;
    width: 100%;
    height: 40%;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    transition:all 0.2s ease-in-out;
    border-radius:0px 0px 50px 50px;
}
.upload-image__overflow >i{
    width:fit-content;
}
.upload-image__file:hover ~ .upload-image__overflow{
    opacity:0.8
}
.upload-image__file{
    width:100%;
    height:100%;
    margin-left: -112px;
    z-index:99;
    position:absolute;
    opacity:0;
    cursor:pointer
}

.image-upload-content{
    width: 70%;
    height: 100%;
    margin: auto auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.image-upload-content-text{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #3598DC;
    cursor: pointer;
}

.upload-image-alert-box{
    border: 1.5px solid #B5E1FF;
    box-sizing: border-box;
    border-radius: 4px;
    margin: 0 auto;
    width: 359px;
    margin-top: 25px;
    padding: 15px 20px;
}

.upload-image-alert-text{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #999999;
}



@media (max-width: 480px){
    .upload-image-alert-box{
        width: 100%;
        padding: 15px 15px;
    }
}

@media (max-width: 351px){
    .upload-image{
        width: 100%;
    }
    .upload-image__file{
        margin-left: (-112px + 15px);
    }
}