.activation-container-wrapper{
    width: 100%;
    margin: 0 auto;
    height: 100vh;
}
.activation-binabud-logo{
    padding-top: 47px;
}

.logo-binabud-activation{
    width: 350px;
    transition: 0.5s;
}

.activation-contain-text{
    display: flex;
    flex-direction: column;
    margin-top: 15px;
}

.activation-font-top{
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    /* identical to box height */
    color: #444444;
}

.activation-font-middle-container{
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #666666;
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    margin-top: 20px;
}

.blue-color{
    color: #3598DC;
    margin-left: 5px;
    cursor: pointer;
}

.blue-color:hover{
    text-decoration: underline;
}

.blue-color-1239888{
    color: #3598DC;
    margin-left: 5px;
    cursor: pointer;
}

.blue-color-1239888:hover{
    text-decoration: underline;
}


.black-color{
    color: #666666;
}

.activation-container-card{
    transition: 0.5s;
    background-color: white;
    border: 0.5px solid #ECECEC;
    box-sizing: border-box;
    border-radius: 4px;
    width: 458px;
    margin: 0 auto;
    margin-top: 54px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 36px;
    padding-left: 29px;
    padding-right: 29px;
    padding-bottom: 73px;
    margin-bottom: 20px;
}

.activation-input-theme{
    width: 100%;
    height: 44px;
    background: #FAFAFA;
    border: 0.5px solid #D1D1D1;
    box-sizing: border-box;
    border-radius: 4px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    transition: 0.5s;
    padding: 0px 19px;
    margin-top: 10px;
}

.activation-input-theme::placeholder {
    color: #D1D1D1;
}

.activation-input-theme:focus{
    outline: none;
    box-shadow: 0px 0px 5px 2px #B0DFFF;
    border: 1px solid #B0DFFF;
}

.card-font-text{
    font-family: 'Roboto', sans-serif;
    margin-right: auto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #666666;
}

@media (max-width: 470px){
    .activation-container-card{
        width: 350px;
    }
}

@media (max-width: 365px){
    .activation-container-card{
        width: 300px;
    }
    .logo-binabud-activation{
        width: 300px;
    }
    .activation-font-top{
        padding: 0px 15px;
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
    }
    .activation-font-middle-container{
        flex-direction: column;
    }
    .blue-color-1239888{
        margin-top: 10px;
    }
}