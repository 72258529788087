.hasil-seleksi-wrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px;
    box-sizing: border-box;
}

.hasil-seleksi-header{
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #444444;
    width: 100%;
}

.hasil-seleksi-wrapper-second{
    width: 100%;
}

.hasil-seleksi-image-wrapper{
    display: flex;
    width: 100%;
    margin-bottom: 25px;
    align-items: flex-start;
}

.daftar-table{
    width: 100%;
    margin-top: 13px;
}

.daftar-table-wrapper{
    width: 100%;
    padding: 30px 20px;
}

.daftar-table .MuiTablePagination-actions{
    display: none !important;
}

.daftar-table .MuiToolbar-gutters{
    padding-left: 0px !important;
}

.daftar-filter-table{
    display: flex;
    justify-content: flex-start;
    width: 100%;
}

.search-filter{
    display: flex;
    flex-direction: row;
    padding: 0px;
    // border: none;
    align-items: center;
    width: 385px;
    margin-right: 12px;
}

.filter-table{
    background: #FAFAFA;
    border: 0.5px solid #D1D1D1;
    box-sizing: border-box;
    border-radius: 4px;
    width: 100%;
    height: 44px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #C9C9C9;
    padding: 0px 14px;
}

.filter-table:focus{
    outline: none;
}

.filter-table::placeholder{
    color: #C9C9C9;
}

/* arrows */
select.filter-table {
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url('../../images/inputData/dropdown.png');
    background-repeat: no-repeat;
    background-position: calc(100% - 10px) calc(1em + 2px);
}

.search-custom-filter{
    color: #C9C9C9;
    margin-left: auto;
    padding: 11px 12px;
    height: 20px;
    border: 0.5px solid rgb(209, 209, 209);
    border-radius: 0px 4px 4px 0px;
}

.filter-table-peserta-placeholder{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: #999999;
    margin-top: -5px;
}

.value-selectable-disabled{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-align: center !important;
    color: #D0D0D0;
    height: 34px;
    border-bottom: 0.5px solid #EBEBEB !important;
}

.value-selectable-disabled.MuiListItem-root{
    justify-content: center !important;
}

.value-selectable-active{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-align: center !important;
    background: #EBF5FB !important;
    color: #3598DC !important;
    height: 34px;
    display: flex;
}

.value-selectable-active.MuiListItem-root{
    justify-content: center !important;
}

.value-selectable{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-align: center !important;
    color: #666666 !important;
    height: 34px;
    display: flex;
    border-top: 0.5px solid #EBEBEB !important;
}

.value-selectable:hover{
    background: #EBF5FB !important;
    color: #3598DC !important;
}

.table-hover-style-custom{
    transition: 0.5s;
    cursor: pointer;
}

.table-hover-style-custom:hover{
    background-color: #F6FCFF;
}

.pagination-wrapper{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.pagination-left{
    display: flex;
    justify-content: flex-start;
}

.filter-drawer-header{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 75px;
    padding-left: 30px;
    padding-right: 20px;
}

.filter-header-font-drawer-top{
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    text-align: center;
    color: #444444;
}

.filter-reset-font-drawer{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    text-align: center;
    color: #3598DC;
    cursor: pointer;
}

.drawer-row{
    display: flex;
    margin-top: 5px;
    flex-direction: row;
    margin-left: 5px;
}

.drawer-column{
    display: flex;
    margin-top: 5px;
    flex-direction: column;
    margin-left: 5px;
    margin-bottom: 20px ;
}

.filter-title-big-black{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: #444444;
}

.filter-nama-drawer{
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.filter-chapter-drawer{
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.label-checkbox-drawer{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #999999;
    text-transform: capitalize;
}

.label-checkbox-drawer-active{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #3598DC;
    text-transform: capitalize;
}

@media (max-width: 500px) {
    .daftar-filter-table{
        flex-wrap: wrap;
    }
    .search-filter{
        width: 100%;
        margin-right: 0px;
        margin-bottom: 10px;
    }
}

@media (max-width: 425px) {
    .hasil-seleksi-image-wrapper{
        align-items: center;
        justify-content: center;
    }
}