.stepper-content{
    padding-left: 20px;
    margin-left: 10.5px;
    padding-top: 10px;
    padding-bottom: 20px;
}

.MuiStepConnector-root{
    display: none;
}

.information-chapters-pribadi-09013{
    padding-bottom: 20px;
    border-bottom: 1px solid #a0a1a0;
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: left;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #000000;
}

.txt-blur-uinfop2131{
    color: #3c98dc;
    cursor: pointer;
}