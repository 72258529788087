.label-lupa-password{
    margin-top: 11px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: right;
    color: #3598DC;
    cursor: pointer;
}

.label-lupa-password:hover{
    text-decoration: underline;
}

.change-pass-text{
    margin: 0 auto;
    margin-top: 15px;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #555555;
    width: 270px;
}