.input-widther{
    width: 100%;
    height: 44px;
    border-radius: 4px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #666666;
}

.input-widther:focus{
    outline: none;
}

.input-widther::placeholder{
    color: #C9C9C9;
}

.data-pribadi-input2 .input-widther{
    text-align: left;
}