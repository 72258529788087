.program-selection{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #666666;
    width: 100%;
    height: 44px;
    background: none;
    border: none;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 0px 19px;
}

.data-pribadi-input:focus{
    outline: none;
}

.data-pribadi-input::placeholder{
    color: #C9C9C9;
}

.text-pilihan-program{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #666666;
    min-width: 270px;
    text-align: left;
}