.input-container-2021{
    border-radius: 4px;
    width: 100%;
    margin-top: 20px;
    background-color: #FFFFFF;
}

.input-wrapper-2021{
    padding: 30px 20px;
}

.input-wrapper-2021-margintop{
    margin-top: 15px;
}

.data-pribadi-header{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    text-transform: uppercase;
    color: #444444;
    height: 60px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
    padding-left: 19px;
    box-shadow: 0px 1px 2px rgba(117, 117, 117, 0.1);
}

.MuiBox-root-21{
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.data-pribadi-input-area{
    display: flex;
    flex-direction: column;
    padding: 30px 21px;
    background-color: #FFFFFF;
    margin-top: 2px;
    border-radius: 4px;
}

.data-pribadi-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.data-pribadi-font{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #666666;
    margin-right: 31px;
    min-width: 250px;
    max-width: 250px;
    text-align: right;
}

.data-pribadi-input{
    width: 100%;
    height: 44px;
    background: #FAFAFA;
    border: 0.5px solid #D1D1D1;
    box-sizing: border-box;
    border-radius: 4px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    padding: 0px 19px;
    color: #666666;
    transition: 0.5s;
}

.data-pribadi-input:focus{
    outline: none;
}

.data-pribadi-input::placeholder{
    color: #C9C9C9;
}

.data-pribadi-input2{
    width: 49.5%;
    height: 44px;
    background: #FAFAFA;
    border: 0.5px solid #D1D1D1;
    box-sizing: border-box;
    border-radius: 4px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    padding: 0px 19px;
    color: #666666;
}

.data-pribadi-input2:focus{
    outline: none;
}

.data-pribadi-input2::placeholder{
    color: #C9C9C9;
}

.react-date-picker__wrapper{
    border: none !important;
}

.react-date-picker__inputGroup__input{
    color: #666666 !important;
}

.react-calendar{
    width: 93% !important;
}

.data-pribadi-input3{
    width: 32.8%;
    height: 44px;
    background: #FAFAFA;
    border: 0.5px solid #D1D1D1;
    box-sizing: border-box;
    border-radius: 4px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    padding: 0px 19px;
    color: #666666;
}

.data-pribadi-input3:focus{
    outline: none;
}

.data-pribadi-input3::placeholder{
    color: #C9C9C9;
}

/* arrows */
select.data-pribadi-input3 {
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url('../../../../images/inputData/dropdown.png');
    background-repeat: no-repeat;
    background-position: calc(100% - 10px) calc(1em + 2px);
}

/* arrows */
select.data-pribadi-input {
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url('../../../../images/inputData/dropdown.png');
    background-repeat: no-repeat;
    background-position: calc(100% - 10px) calc(1em + 2px);
}

.data-pribadi-button-wripper{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 25px;
    border-radius: 0px 0px 4px 4px;
    background-color: #FFFFFF;
}

.data-pribadi-button-wripper-finalisasi{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 25px;
    border-radius: 0px 0px 4px 4px;
    background-color: #FFFFFF;
}

.action-wrapper-2021-finalisasi{
    margin-top: 30px;
}

.action-wrapper-2021-margintop{
    margin-top: 30px;
    border-top: 1px solid rgb(240, 240, 240);
}

.data-pribadi-button-wripper-2{
    display: flex;
    margin-left: auto;
}

/* Chrome, Safari, Edge, Opera */
.data-pribadi-input::-webkit-outer-spin-button,
.data-pribadi-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.data-pribadi-input[type=number] {
    -moz-appearance: textfield;
}

.btn-save-input2021-out-finalisasi{
    width: 200px;
}

.btn-save-input2021-next-finalisasi{
    width: 200px;
    margin-left: 15px !important;
}

.btn-save-input2021-out{
    width: 200px;
}

.btn-save-input2021-next{
    width: 200px;
    margin-left: 15px !important;
}

.title-input-desktop{
    transition: 0.5s;
    display: block;
}

.title-input-mobile{
    display: none;
    transition: 0.5s;
}

.data-pribadi-font-mobile{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #666666;
    text-align: left;
}

.data-pribadi-wrapper-mobile{
    margin-top: 0px;
}

.title-input-mobile2row{
    margin-top: 16px;
    display: none;
    transition: 0.5s;
}

.input-wrapper-data-pribadi{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    transition: 0.5s;
}

.input-wrapper-data-pribadi-radio{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    transition: 0.5s;
}

.data-pribadi-input2-margin-top-mobile{
    margin-top: 0px;
}

@media (max-width: 900px){
    .data-pribadi-input2{
        width: 100%;
    }
    .data-pribadi-input-margin-top-mobile{
        margin-top: 6px;
    }
    .data-pribadi-input3{
        width: 100%;
    }
    .input-wrapper-data-pribadi{
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    .title-input-desktop{
        display: none;
    }
    .title-input-mobile{
        display: block;
    }
    .title-input-mobile2row{
        display: block;
    }
    .data-pribadi-wrapper-mobile{
        margin-top: 6px;
    }
    .input-wrapper-2021-margintop{
        margin-top: 6px;
    }
}


@media (max-width: 725px){
    .data-pribadi-button-wripper-finalisasi{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .btn-save-input2021-next-finalisasi{
        margin-left: 0px !important;
        margin-top: 15px !important;
    }
}

@media (max-width: 430px){
    .data-pribadi-button-wripper{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 25px;
        border-radius: 0px 0px 4px 4px;
        background-color: #FFFFFF;
    }
    .btn-save-input2021-next{
        margin-left: 0px !important;
        margin-top: 15px !important;
    }
}

@media (max-width: 340px){
    .input-wrapper-data-pribadi-radio{
        align-items: flex-start;
        flex-direction: column;
    }
}